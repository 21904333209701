

















































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import {apiCustomerLists,apiShopLists,getFestivalLists,getTemplateLists,getCompanyTemplateLists
,getTemplateInfo,getCompanyTemplateInfo,apiGetStaffs,apiCreate,apiOrderActive} from "@/api/card_order/order"
import ServerPagination from "@/components/server-pagination.vue"
import engineH5Swiper from "@/template-editor/engine-template/engine-h5-swiper/src/index.vue";
import configs from "@/config";
import {apiimportIndex} from "@/api/shop";
import LsDialog from "@/components/ls-dialog.vue";
import LsUpload from '@/components/ls-upload.vue'
import {Message} from "element-ui";

@Component({
    components: {
        MaterialSelect,
		ServerPagination,
		engineH5Swiper,
		LsDialog,
		LsUpload
    },
})
export default class pointOrderAdd extends Vue {
    steps: any = 0;
    multipleSelection: any[] = [];
    isSelectEmployee: boolean = true; //是否选择员工
    allCheck: boolean = false;
    tableData: any[] = [
    ];
    searchObj: any = {
        name: "",
        phone: "",
        birthday: "",
    };
	color='#999';
	discount_text='折扣率不能大于1，最多可填写2位小数';
    showActive:boolean=false;
    orderId:any=0;
    form: any = {
    	customer_id:'',
		shop_id:"",
		expire:"",
		welfare_name: "",
		is_auto_bind:1,
		sms_content:"",
		send_type:1,
		send_time:"",
		send_time2:"",
		greeting_card:0,
		greeting_id:0,
		remark:'',
		price:0,
		user_ids:[],
		integral_discount:"",
		status:0,
        bqbz: "",
        ffxs: 1,
        qs: 1,
        zdbd: 1,
        ffsj: 1,
        pzhk: 2,
        ffrs: "",
        mrff: "",
        mrjf: "",
        xszk: "",
        ddzje: "",
        ffdx: 1,
        email: "",
        phone: "",
        yjnr: "",
        yjzt: "",
    };

	customers: any[] = [];
	shops: any[] = [];
	step3_data :any={
		search:  {
			type: 4,
			status: 1,
			count: 0,
			page_no: 1,
			page_size: 12,
		},
		scenes_id:0,
		list:  [],
		loading:  false,
		count: {"1": 36, "2": 24, "3": 10, "4": 3}
	};
	step4_data :any={
		platformUrl:"",
		search :{
			type_id: this.$route.query.id ?? 0,
			type: 'normal',
			system: 'system',
			count: 0,
		},
		list:[],
		loading:false
	}
	step5_data :any={
		selected:[],
		emailData:{},
		platformUrl: {},
		editId:  0,
		viewType: 0,
		showEditEmail:  false,
		showEditor:false,
		loading:false,
		id:0,
		view_type:1,
		key:0,
	}
	step6_data :any={
		search:{
			name:"",
			phone:"",
			birthday:"",
			page_no:1,
			page_size:10000,
		},
		list:  [],
	}
	step7_data :any={}
	async created(){
		let local_id :any=this.$route.query.local_id
		if(local_id>0) {
			let local_data=localStorage.getItem('card_order_new_'+local_id);
			if(local_data) {
				let localData: any = JSON.parse(local_data || "{}")
				Object.assign(this, localData)
				this.step5_data.loading = true
				let new_id :any = this.$route.query.new_id;
				if (new_id > 0) {
					this.step5_data.id = new_id;
					this.step5_data.view_type = 2;
				}
				let data
				if (this.step5_data.view_type == 1) {
					data = await getTemplateInfo({id: this.step5_data.id})
				} else {
					data = await getCompanyTemplateInfo({id: this.step5_data.id})
				}
				//localStorage.removeItem('card_order_new_' + local_id)
				localStorage.setItem('new-care-template-data', JSON.stringify(data))
				this.setTemplateData();
				this.step5_data.loading = false
				this.step5_data.key = local_id;
			}
		}
		await this.getCustomers();
		this.getGreetCardList()
		await this.getshops();
	};
	async getCustomers():Promise<void> {
		this.customers = await apiCustomerLists({ keyword: '' });
	};
	async getshops():Promise<void> {
		this.shops = await apiShopLists({ customer_id: this.form.customer_id });
		this.form.shop_id="";

	};
	disableOld={
		disabledDate: (time:any) => {
			return time.getTime() < Date.now()- 8.64e7;
		}
	};
	priceChange(value:any){
		if(!Number.isInteger(value)){
			this.form.price=Math.round(value);
		}
		if(value<0){
			this.form.price=1;
		}

		if(!Number.isInteger(value)&&value<1){
			this.form.price=1;
		}
	};
	discountChange(value:any){
		if(!Number.isInteger(value)){
			this.form.integral_discount=(value*1).toFixed(2);
		}
	};
	sendTimeValidator(rule: any, value: any, callback: any){
		if(this.form.send_type==2&&value==''){
			return callback(new Error("请选择发放日期"));
		}
		if(this.form.send_type==4&&value<=0){
			return callback(new Error("请选择生日前几天发放"));
		}
		callback();
	};

	codeValidator(rule: any, value: any, callback: any){
		if(value==''){
			return callback(new Error("请输入短信内容"));
		}
		if(!value.includes("{$code}")&&this.form.is_auto_bind==0){
			return callback(new Error("请填写兑换码{$code}生成位置"));
		}
		callback();
	};
	checkzk(rule: any, value: any, callback: any){
		if (!value||value>1||value<=0) {
			this.color="#ff0000";
			return callback(new Error(" "));
		}
	/*	if (Number.isNaN(value)) {
			callback(new Error("请正确输入销售折扣"));
		} else {
			if (value > 1) {
				callback(new Error("折扣率不能大于1，最多可填写2位小数"));
			} else {
				callback();
			}
		}*/
		this.color="#999";
		callback();
	};

	// 表单校验
	rules = {
		customer_id: [{ required: true, message: "请选择客户", trigger: "blur" }],
		shop_id: [{ required: true, message: "请选择商城", trigger: "blur" }],
		expire: [{ required: true, message: "请选择积分有效期", trigger: "blur" }],
		welfare_name: [{ required: true, message: "请输入福利名称", trigger: "blur" }],
		sms_content: [{ validator: this.codeValidator,  trigger: "blur" }],
		send_time: [{ validator: this.sendTimeValidator, trigger: "blur"}],
		price: [{ required: true,/*type:'number',*/ message: "请输入每人发放积分额", trigger: "blur" }],
		integral_discount: [{ validator:this.checkzk, trigger: "blur" }],
	};
	async getGreetCardList() {
		this.step3_data.loading = true
		let res = await getFestivalLists(this.step3_data.search);
		this.step3_data.list = res.lists
		this.step3_data.search.count = res.count
		this.step3_data.loading = false
	}
	async selectTemplate(info: any) {
		this.step4_data.search.type_id=info.id;
		await this.getGreetCardTemplateList();
		this.steps++;
	}
	async changeSystem(system: any) {
		this.step4_data.search.system = system
		await this.getGreetCardTemplateList()
	}
	async getGreetCardTemplateList() {
		this.step4_data.loading = true
		let res
		let search = {
			fuli_type_id: this.step4_data.search.type_id,
			page_size: 999,
		}

		if (this.step4_data.search.system === 'system') {
			res = await getTemplateLists(Object.assign(search, {status: 1}))
		} else {
			res = await getCompanyTemplateLists(search);
		}

		this.step4_data.list = res.lists
		this.step4_data.search.count = res.count
		this.step4_data.loading = false

	}
	showQr(index: any) {
		let item: any = this.step4_data.list[index]
		item.showQr = true
		this.$set(this.step4_data.list, index, item)
	}

	closeQr(index: number) {
		let item: any = this.step4_data.list[index]
		item.showQr = false
		this.$set(this.step4_data.list, index, item)
	}
	async newCare(item: any) {
		localStorage.setItem('new-care-template-data', '')

		this.steps++;

		this.step5_data.loading = true
		let data
		this.step5_data.view_type=this.step4_data.search.system === 'system'?1:2;
		this.step5_data.id=item.id;
		if (this.step5_data.view_type==1) {
			data = await getTemplateInfo({id: item.id})
		} else {
			data = await getCompanyTemplateInfo({id: item.id})
		}
		localStorage.setItem('new-care-template-data', JSON.stringify(data))
		this.setTemplateData();
		this.step5_data.key=new Date().getTime();
		this.step5_data.loading = false
	}
	setTemplateData(){
		let templateData: any = JSON.parse(localStorage.getItem('new-care-template-data') || "{}")
		let h5Data = JSON.parse(templateData.template_h5_datas);console.log(h5Data);
		let windows: any = window
		windows._pageData = h5Data
		this.step5_data.showEditor = true
	};
	editTemplate() {
		localStorage.setItem('new-care-template-data', '')
		let localId :any=new Date().getTime();
		this.setCardOrderToLocal(localId);
		this.$router.push({
			path: '/card/pointManage/template',
			query: {id: this.step5_data.id,local_id:localId, view_type: this.step5_data.view_type}
		})
	}
	setCardOrderToLocal(localId:any){
		localStorage.setItem('card_order_new_'+localId,JSON.stringify({
			steps:this.steps,
			form:this.form,
			step3_data:this.step3_data,
			step4_data:this.step4_data,
			step5_data:this.step5_data,
			step6_data:this.step6_data,
			step7_data:this.step7_data,
		}));
	};
	async downLoad() {
		const url = configs.baseURL+'/platformapi/staff.Staff/export';
		window.open(url, '_blank');

	}
	async imports() {
		const data = {
			id: this.$route.query.id,
		};

		await apiimportIndex({id:this.form.customer_id});
		this.getStaff();

	}
	uploadChange(e:any){
		if(e=='close'){
			this.getStaff();
		}
	}
	async getStaff(){
		let res=await apiGetStaffs(Object.assign(this.step6_data.search,{platform_business_id:this.form.customer_id}));
		this.step6_data.list=res.lists;
	};
	// 确认选择员工
	selectEmployees() {
		if (this.form.price == "") {
			return this.$message.error("请输入每人发放积分");
		}
		this.form.user_ids=[];
		/*
		console.log(this.multipleSelection);*/
		for(let k in this.multipleSelection){
			this.form.user_ids.push(this.multipleSelection[k].id);
		}
		this.isSelectEmployee = false;
	}
	// 多选
	handleSelectionChange(val: any) {
		this.multipleSelection = val;
	}
	reselectEmployees() {
		this.allCheck = false;
		this.multipleSelection = [];
		this.form.user_ids=[];
		this.isSelectEmployee = true;
	}

	allCheckbox(value: boolean) {
		this.allCheck = value;
		const tableRef = this.$refs.multipleTable as any;
		value ? tableRef.toggleAllSelection() : tableRef.clearSelection();
	}


	jumpList(){
		this.$router.push({
			path: '/card/pointManage/pointOrder',
			query: {}
		})
	}
	async activeOrder(){
		let res=await apiOrderActive({ids:[this.orderId],status:1})
		this.jumpList();
	}

    // 上下页
    prev() {
        // 邮件发放直接到5
        /*if (this.steps == 5 && this.form.ffxs == 1) {
            this.steps = 2;
        } else {
            this.steps--;
        }*/
		//未选择了贺卡 直接到3
		if(this.steps==6&&this.form.greeting_card==0){
			this.steps = 2;
			return;
		}
		this.steps--;
    }
     next(formName: string) {
        // 验证表单
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
			/*let localId=new Date().getTime();
			this.setCardOrderToLocal(localId);*/
            //未选择了贺卡 直接到7
			if(this.steps==2&&this.form.greeting_card==0){
				this.steps = 6;
				this.getStaff();
				return;
			}
			if (this.steps == 3 && this.step4_data.search.type_id ==0 ) {
				return this.$message.error("请选择贺卡类型");
			}
			if (this.steps == 4 && this.step5_data.id==0) {
				return this.$message.error("请选择贺卡模板");
			}
            // 选择员工
            if (this.steps == 6 && this.isSelectEmployee) {
                return this.$message.error("选中员工后，再点击确认选择");
            }
            // 邮件发放直接到5
            /*if (this.steps == 2 && this.form.ffxs == 1) {
                this.steps = 5;
            } else {
                this.steps++;
            }*/

			this.steps++;
			if (this.steps == 6) {//获取一次员工
				this.getStaff();
			}
        });
    }
	can_submit=true;
    // 点击表单提交
     onSubmit(formName: string) {
		 if(!this.can_submit)return;
		 this.can_submit=false;
    	this.form.greeting_id=this.step5_data.id;
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate(async (valid: boolean) => {
            if (!valid) return;
            this.form.integral_start=this.form.expire[0];
			this.form.integral_end=this.form.expire[1];
			this.form.greeting_type=this.step5_data.view_type;
			let res= await apiCreate(this.form);
			this.orderId=res.id;
			this.showActive=true;
			this.can_submit=true;
        });
		 this.can_submit=true;
    }
}
